import React from 'react'

const Cart = ({cartItem, addCart}) => {
  console.log('productItem')
  return (
   <>
   <section className='cart-items'>
        <div className='container d_flex'>
          {/* if hamro cart ma kunai pani item xaina bhane no diplay */}

          <div className='cart-details'>
            {cartItem.length ===0 && <h1 className='no-items product'>Không có sản phẩm nào trong giỏ hàng</h1>}

            {/* yasma hami le cart item lai display garaaxa */}
            {cartItem.map((item) => {
              const producQty = item.price*item.producQty
              return (
                <div className='cart-list product d_flex' >
                  <div className='img'>
                    <img src={item.cover} alt='' />
                  </div>
                </div>
              )})}
           </div>
           </div>

             
      </section>
   </>
  )
}

export default Cart
